.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  scale: 0.9 !important;
}

.swiper-pagination-bullet-active {
  scale: 1 !important ;
  background: white !important;
}

.rssas-slider-dot {
  background-color: #2d429c;
}

.main {
  display: grid;
  width: 100%;
  max-width: 1000px;
  height: 800px;
  max-height: 800px;
  gap: 0.5rem;
  grid-template-columns: repeat(2, 1fr);
  padding: 0.5rem;
}

@media screen and (min-width: 640px) {
  .main {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .article:nth-child(1) {
    grid-area: 1 / 1 / 4 / 3;
  }
  .article:nth-child(2) {
    grid-area: 1 / 3 / 4 / 5;
  }
  .article:nth-child(3) {
    grid-area: 1 / 5 / 4 / 7;
  }
  .article:nth-child(4) {
    grid-area: 4 / 4 / 7 / 7;
  }
  .article:nth-child(5) {
    grid-area: 4 / 1 / 7 / 4;
  }
}

@media screen and (max-width: 635px) {
  .main {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 800px) {
  #panels_wrapper {
    overflow: hidden;
    height: 100vh;
    top: 0;
    position: relative;
  }

  .panel {
    padding: '0 15px';
    width: 100%;
    height: 90%;
    isolation: isolate;
    position: absolute;
    bottom: 0;
  }

  .panel p {
    text-align: center;
  }

  .panel:nth-of-type(1) {
    transform: translateY(0) scale(0.85);
  }
  .panel:nth-of-type(2) {
    transform: translateY(85%) scale(0.9);
  }
  .panel:nth-of-type(3) {
    transform: translateY(90%) scale(0.95);
  }
  .panel:nth-of-type(4) {
    transform: translateY(95%) scale(1);
  }
}

@media (max-width: 799px) {
  .panel {
    margin-top: 20px;
  }
}

.partners .swiper-wrapper {
  transition-timing-function: linear !important;
}
